import React, { useState } from "react";
// import { useLocation} from "react-router-dom";
import { makeStyles } from '@material-ui/core/styles';

import LoadSpinner from '../LoadSpinner/LoadSpinner';
import {requestVerification, ExpireBox, ErrorBox} from './General';

import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import Typography from '@material-ui/core/Typography';

import Box from '@material-ui/core/Box';
import Container from '@material-ui/core/Container';

const useStyles = makeStyles((theme) => ({
     checkboxer: {
      fontSize:'15px'
     },
     icon: {
      fontSize:'70px'
     },
     inputField: {
        margin: '10px 0',
        padding: '10px',
        width:'100%'
     },
     formBox: {
        margin:'40px 0',
        padding:'5px',
        borderRadius:'5px',
        background: 'rgba(0,0,0,0.1)',
        border: '2px solid black'
     },
      verifyBox: {
        margin:'40px 0',
        padding:'20px',
        borderRadius:'5px',
        background: 'rgba(140,140,140,0.1)',
        border: '2px solid white',
        textAlign: 'center'
      },
      successBox: {
        margin:'40px 0',
        padding:'20px',
        borderRadius:'5px',
        background: 'rgba(0,255,0,0.1)',
        border: '2px solid green',
        textAlign: 'center'
      }
  }));

//<p>
//  <button className="btn" onClick={() => requestVerification(submitData)}>Re-Send E-mail</button>
//</p>

const SuccessBox = ({success,submitData}) => {

   const classes = useStyles();

  return (
    <div>
     <Container maxWidth="sm">
      {
        success === "registered" 
        ?
         <Box className={classes.verifyBox}>
            <h2>Almost there!</h2>
            <Typography className={classes.icon}>
              <span role="img" aria-label="envelope">✉️</span>
            </Typography>
            {
              <div>
                <p>Please verify your email by following the link that was sent to {`${submitData.email}`}</p>
                <p>If this link has expired, you will have to generate this form again from POLLEN's <a href={`https://slack.com/app_redirect?app=${process.env.REACT_APP_SLACK_APP_ID}`}>Home Tab</a>.</p>
              </div>
            }
          </Box>
          :
          success === "updated" ?
            <Box className={classes.successBox}>
              <h2>Updated!</h2>
              <p>Your details have been updated.</p>
            </Box>
         :
            <ExpireBox/>
      }
      </Container>
    </div>
   )

}

const FormBody = ({isLoading,hasError,status}) => {

  const classes = useStyles();

  const [checked, setChecked] = useState(false);

  const handleCheck = (event) => {
    setChecked(event.target.checked);
  };

    return (
      <div>
        <Box m={2}>
          <label htmlFor="nameField">Name </label>
          <input className={classes.inputField}
          type="text"
          name="name"
          placeholder="name"
          required
          />
        </Box>
        <Box m={2}>
          <label htmlFor="organization">Organization</label>
          <input className={classes.inputField}
          type="text"
          name="organization"
          placeholder="organization (optional)"
          />
        </Box>
        <Box m={2}>
          <label htmlFor="email">Email </label>
          <input className={classes.inputField}
          type="email"
          name="email"
          placeholder="email"
          required
          />
        </Box>
        {
        !status ? 
            <Box m={2}>
             <FormControlLabel
              fontSize={10}
              control={
                <Checkbox
                  checked={checked}
                  onChange={handleCheck}
                  name="checked"
                  color="primary"
                  required
                />
              }
              label={
                 <Typography className={classes.checkboxer}>
                    <span>I agree with POLLEN's </span>
                    <a href="/terms-of-use">terms of use</a>
                    <span> and </span>
                    <a href="/privacy">privacy policy</a>
                 </Typography>
               }
            />
             </Box>
          : ''
       }
       <Box m={2}>
           {isLoading ? <LoadSpinner /> : <button className="btn">{!status ? 'Register' : 'Update'}</button>}
        </Box>
      </div>
   ) 
}

function Form({isTeam,isUser,isCode,status}) {

  const [submitData, setSubmitData] = useState(false);

  const [isLoading, setIsLoading] = useState(false);
  const [success, setSuccess] = useState('none');
  const [hasError, setHasError] = useState(false);

  const classes = useStyles();

  const handleSubmit = async (e) => {
    e.preventDefault();

    setIsLoading(true)

    const formData = new FormData(e.target);

    let opts = {
      team:isTeam,
      user:isUser,
      code:isCode
    };

    for (let [key, value] of formData.entries()) {
      opts[key] = value;
    }

    const response = await requestVerification(opts);

    // const response = 'registered'; // test 'registered'
    // const response = 'updated';    // test 'updated'
    // const response = 'expired';    // test 'expired'

    console.log('response');
    console.log(response);

    if (response === 'error') {
      setHasError(true);
      setSuccess('none');
      return
    }

    setSuccess(response);
    
    if (success) {
      setSubmitData(opts)
    }
    else {
      setSuccess('none');
      setIsLoading(false);
     }
  };

  return (
    <div>
      {
        success !== 'none' ?
         <SuccessBox success={success} submitData={submitData}/>
        : 
        hasError ? 
          <ErrorBox/>  :
          <div>
          <Container maxWidth="sm">
            {
              !status ? 
                <Box py={2} textAlign="center">
                   Register your team with <a href="https://pixelsandplans.com">Pixels and Plans</a> to see your channels.
                </Box>
             :
               status === "pending" ?
                  <Box textAlign="center">
                      <p>You are registered, but you never verified your email.</p>
                      <p>Re-submit your information using the form below.</p>
                      <p>Don't forget to verify your email!</p>
                  </Box>
                :
                  <Box textAlign="center">
                     <p>You can update your information below.</p>
                     <p>You will be asked to verify your email if it is different from the one we have on record.</p>
                  </Box>
            }
            <Box className={classes.formBox}>
              <form onSubmit={handleSubmit}>
                <FormBody isLoading={isLoading} hasError={hasError} status={status}/>
              </form>
            </Box>
          </Container>
          </div>
      }
      </div>
    );
  }

export default Form;