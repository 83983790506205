import React from 'react';
import {Route, Switch} from "react-router-dom";
import {NavLink} from "react-router-dom";

import clsx from 'clsx';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import CssBaseline from '@material-ui/core/CssBaseline';

import Footer from "../Footer/Footer";

import Drawer from '@material-ui/core/Drawer';
import AppBar from '@material-ui/core/AppBar';

import Divider from '@material-ui/core/Divider';
import IconButton from '@material-ui/core/IconButton';

import MenuIcon from '@material-ui/icons/Menu';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';


import Toolbar from '@material-ui/core/Toolbar';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
//import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';

import Home from "../../Home";
import About from "../../About";
import Guide from "../../Guide";
import Register from "../../Register";
import Remove from "../../Remove";
import Cancel from "../../Cancel";
import Update from "../../Update";
import Upgrade from "../../Upgrade";
import Privacy from "../../Privacy";
import Terms from "../../Terms";
import Support from "../../Support";

const drawerWidth = 240;

const NoMatchPage = () => {
  return (
    <h3>404 - Not found</h3>
  );
};

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
  },
  pageHolder: {
    display:'flex',
    flexDirection:'column',
    height:'100vh'
  },
  mainPage:{
    flexGrow:1
  },
  appBar: {
    transition: theme.transitions.create(['margin', 'width'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  appBarShift: {
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(['margin', 'width'], {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
    marginRight: drawerWidth,
  },
  title: {
    flexGrow: 1,
    textAlign:'left',
    display:'flex',
    wrap:'column'
  },
  hide: {
    display: 'none',
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
  },
  drawerPaper: {
    width: drawerWidth,
  },
  drawerHeader: {
    display: 'flex',
    alignItems: 'center',
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
    justifyContent: 'flex-start',
  },
  content: {
    flexGrow: 1,
    padding: 0,
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    marginRight: -drawerWidth,
    zIndex:1
  },
  contentShift: {
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
    marginRight: 0,
  },
  active: {
    backgroundColor: 'rgba(255, 255, 255, 0.12)',
  },
  logo: {
    maxWidth: 160,
    alignItems: 'center',
    width: "100%"
  },
  drawList: {
    color:'#000'
  },
}));

const PersistentDrawer = () => {

  const classes = useStyles();
  const theme = useTheme();
  const [open, setOpen] = React.useState(false);

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };

  return (
  	 <div className={classes.root}>
      <CssBaseline />
      <AppBar
        position="fixed"
        className={clsx(classes.appBar, {
          [classes.appBarShift]: open,
        })}
      >
        <Toolbar>
          <NavLink to="/" className={classes.title}>
            <img src="pollen_logo.svg" alt="logo" className={classes.logo} />
          </NavLink>
          <IconButton
            color="inherit"
            aria-label="open drawer"
            edge="end"
            onClick={handleDrawerOpen}
            className={clsx(open && classes.hide)}
          >
          <MenuIcon />
          </IconButton>
        </Toolbar>
      </AppBar>
      <main
        className={clsx(classes.content, {
          [classes.contentShift]: open,
        })}
      >
        <div className={classes.pageHolder}>
          <div className={classes.drawerHeader} />
            <div className={classes.mainPage}>
              <Switch>
                <Route exact path={`/`} component={Home}/>
                <Route path={`/about`} component={About}/>
                <Route path={`/guide`} component={Guide}/>
                <Route path={`/register`} component={Register}/>
                <Route path={`/remove`} component={Remove}/>
                <Route path={`/cancel`} component={Cancel}/>
                <Route path={`/update`} component={Update}/>
                <Route path={`/upgrade`} component={Upgrade}/>
                <Route path={`/terms-of-use`} component={Terms}/>
                <Route path={`/privacy`} component={Privacy}/>
                <Route path={`/support`} component={Support}/>
                <Route component={NoMatchPage} />
              </Switch>
            </div>
          <div>
          <Footer/>
          </div>
        </div>
      </main>

      <Drawer
        className={classes.drawer}
        variant="persistent"
        anchor="right"
        open={open}
        classes={{
          paper: classes.drawerPaper,
        }}
      >
        <div className={classes.drawerHeader}>
          <IconButton onClick={handleDrawerClose}>
            {theme.direction === 'rtl' ? <ChevronLeftIcon /> : <ChevronRightIcon />}
          </IconButton>
        </div>
        <Divider />
        <List className={classes.drawList}>
          <ListItem button component={NavLink} to="/" key='home' exact activeClassName={classes.active} onClick={handleDrawerClose}>
         	  <ListItemText primary= 'Home'/>
          </ListItem>
          <ListItem button component={NavLink} to="/about" key='about' activeClassName={classes.active} onClick={handleDrawerClose}>
            <ListItemText primary= 'About'/>
          </ListItem>
          <ListItem button component={NavLink} to="/guide" key='guide' activeClassName={classes.active} onClick={handleDrawerClose}>
         	  <ListItemText primary= 'Guide'/>
          </ListItem>
          <ListItem button component={NavLink} to="/support" key='support' activeClassName={classes.active} onClick={handleDrawerClose}>
         	  <ListItemText primary= 'Support'/>
          </ListItem>
        </List>
      </Drawer>

      </div>
  )
}


export default PersistentDrawer;