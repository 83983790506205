import React from 'react';

import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';

const useStyles = makeStyles((theme) => ({
  footer: {
    backgroundColor: theme.palette.primary.dark,
    color:'#000',
    width: '100%'
  },
  holder:{
  	display:'flex',
  	justifyContent:'center',
  	padding: '20px 0'
  },
  linkHolder: {
  	flexGrow: 0,
  	padding:'10px 20px',
  	fontSize:'13px',
  	color:'#fff'
  },
  link:{
  	color:'#fff'
  }
}));

function Footer() {

  const classes = useStyles();

  return (
	<div className={classes.footer}>
		<div className={classes.holder}>
			<Typography className={classes.linkHolder}>© 2021 Pixels and Plans</Typography>
		    <div className={classes.linkHolder}>
		    	<a className={classes.link} href="/privacy">Privacy Policy</a>
		    </div>
		    <div className={classes.linkHolder}>
		    	<a className={classes.link} href="/terms-of-use">Terms of Use</a>
		    </div>
	    </div>
	</div>
  );
};

export default Footer;