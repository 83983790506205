import React, { useState, useEffect } from "react";
import { makeStyles } from '@material-ui/core/styles';
import { useLocation} from "react-router-dom";

import LoadSpinner from './Components/LoadSpinner/LoadSpinner';
import {ExpireBox, ErrorBox} from './Components/Forms/General';

import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import Container from '@material-ui/core/Container';

const useStyles = makeStyles((theme) => ({
    cancelBox: {
        margin:'40px 0',
        padding:'20px',
        borderRadius:'5px',
        background: 'rgba(0,0,0,0.1)',
        border: '2px solid black',
        textAlign: 'center'
     },
     warnBox: {
        border: '1px solid #FFCC00',
        padding:'20px',
        borderRadius:'5px',
        background: 'rgba(0,0,0,0.1)',
        textAlign: 'center'
      },
      byebye: {
        fontSize:'40px'
      }
 }));

const SuccessBox = ({success}) => {

  const classes = useStyles();

  return (
    <Container maxWidth="sm">
      {
      success === "cancelled" ?
        <Box className={classes.cancelBox}>
          <h2>Cancelled!</h2>
          <p>Your subscription has been cancelled.</p>
           <Typography className={classes.byebye}>
            <span role="img" aria-label="goodbye"> 👋 </span>Bye!<span role="img" aria-label="goodbye"> 👋 </span>
          </Typography>
        </Box>
       :
       success === "no subscription" ?
        <Box className={classes.warnBox}>
          <h2><span role="img" aria-label="warning">⚠️</span> No Subscription was found <span role="img" aria-label="warning">⚠️</span> </h2>
          <p>Your subscription has already been cancelled or you have not subscribed yet.</p>
        </Box>
      :
       <ExpireBox/>
      }
      </Container>
   )

}

const cancelSubscription = async (opts) => {
  try {
    const result = await fetch(`/${process.env.REACT_APP_SLACK}/cancel-subscription`, {
      method: 'post',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(opts)
    })
    const response = await result.json();
    console.log(response);

    return response.status;

   } 
  catch (error) {
    console.log('shit');
    console.log(error);
    return "error";
  }
}

function Cancel() {

	//const [hasTeam, setHasTeam] = useState(false);
  const [isTeam, setIsTeam] = useState(null);
  const [isUser, setIsUser] = useState(null);
  const [isCode, setIsCode] = useState(null);

	const [isLoading, setIsLoading] = useState(false);
	const location = useLocation();

	const [success, setSuccess] = useState('none');
  const [hasError, setHasError] = useState(false);

  const classes = useStyles();

	useEffect(() => {

    if (location.search.length !== 0) {

	      let querystr = location.search.split('?');
        if (querystr.length <= 1) return;

        let entries = querystr[1].split('.');
        if (entries.length <= 2) return;
        // console.log(entries);
        // let team = entries[0].split('=')[1];
        // let user = entries[1].split('=')[1];

        // setIsTeam(team);
        // setIsUser(user);

        setIsTeam(entries[0]);
        setIsUser(entries[1]);
        setIsCode(entries[2]);

	      // setHasTeam(true);
	    }
	  },[location.search]);

	const handleSubmit = async (e) => {
      e.preventDefault();
      console.log('submitting');

      setIsLoading(true)

      let opts = {
        team:isTeam,
        user:isUser,
        code:isCode
      };

      const response = await cancelSubscription(opts);

      if (response === 'error') {
        setHasError(true);
        setSuccess('none');
        return
      }
    
      setSuccess(response);

      if (success) {
      	console.log('success')
      	console.log(success)
      }
      else {
        setSuccess('none');
        setIsLoading(false);
       }
    }

	return (
		<div>
		      {
          hasError ? 
            <div>
              <Box p={4} my={3} textAlign="center">
               <h1><span role="img" aria-label="confused"> 😕 </span>  Cancel Subscription <span role="img" aria-label="confused"> 😕 </span> </h1>
              </Box>
              <ErrorBox/> 
            </div>
          : 
             <div>
               <Box p={4} my={3} textAlign="center">
                 <h1><span role="img" aria-label="pleading"> 🥺 </span>  Cancel Subscription <span role="img" aria-label="pleading"> 🥺 </span> </h1>
               </Box>
               {
                 success !== 'none' ?
                    <SuccessBox success={success}/>
                 :
                    <Container maxWidth="sm">
                      <Box className={classes.cancelBox}>
                        <p>This action will cancel your team's subscription and stop recurring billing.</p>
                        <Box m={2}>
                           {isLoading ? <LoadSpinner /> : <button className="btn" onClick={handleSubmit}>Cancel Subscription</button>}
                        </Box>
                      </Box>
                    </Container>
               }
             </div>
          }
		</div>
		);
	}

export default Cancel;