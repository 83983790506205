import React, { useState, useEffect } from "react";
import { useLocation} from "react-router-dom";

import LoadSpinner from './Components/LoadSpinner/LoadSpinner';
import {ExpireBox, ErrorBox} from './Components/Forms/General';

import { makeStyles } from '@material-ui/core/styles';

import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import Container from '@material-ui/core/Container';

const useStyles = makeStyles((theme) => ({
    removeBox: {
        margin:'40px 0',
        padding:'20px',
        borderRadius:'5px',
        background: 'rgba(0,0,0,0.1)',
        border: '2px solid black',
        textAlign: 'center'
     },
     warnBox: {
        border: '1px solid #FFCC00',
        padding:'20px',
        borderRadius:'5px',
        background: 'rgba(0,0,0,0.1)',
        textAlign: 'center'
      },
      byebye: {
        fontSize:'40px'
      }
 }));


const SuccessBox = ({success}) => {

  const classes = useStyles();

  return (
    <Container maxWidth="sm">
  {
    success === "removed" ?
      <Box className={classes.removeBox}>
        <h2>Removed!</h2>
        <p>Your details have been removed.</p>
        <Typography className={classes.byebye}>
          <span role="img" aria-label="goodbye"> 👋 </span>
        </Typography>
      </Box>
     :
       success === "nouser" ?
         <Box className={classes.warnBox}>
            <h2><span role="img" aria-label="warning">⚠️</span> No account found <span role="img" aria-label="warning">⚠️</span></h2>
            <p>Your details have already been removed, or you have not registered yet.</p>
         </Box>
       :
       <ExpireBox/>
    }
    </Container>
   )

}

const requestRemoval = async (opts) => {

  try {
    const result = await fetch(`/${process.env.REACT_APP_SLACK}/remove`, {
      method: 'POST',
      body: JSON.stringify(opts),
      headers: {"Content-type": "application/json; charset=UTF-8"}
    });

    const response = await result.json();
    console.log(response);

    return response.status;

  } 
  catch (error) {
    console.log('shit');
    console.log(error);
    return "error";
  }

}

function Remove() {

    const [isTeam, setIsTeam] = useState(null);
    const [isUser, setIsUser] = useState(null);
    const [isCode, setIsCode] = useState(null);

	  const [isLoading, setIsLoading] = useState(false);
	  const location = useLocation();

	  const [success, setSuccess] = useState('none');
    const [hasError, setHasError] = useState(false);

     const classes = useStyles();

	useEffect(() => {

    if (location.search.length !== 0) {

	      let querystr = location.search.split('?');
	      if (querystr.length <= 1) return;

	      let entries = querystr[1].split('.');
	      if (entries.length <= 2) return;
	      // console.log(entries);
	      // let team = entries[0].split('=')[1];
	      // let user = entries[1].split('=')[1];

	      // setIsTeam(team);
	      // setIsUser(user);

        setIsTeam(entries[0]);
        setIsUser(entries[1]);
        setIsCode(entries[2]);

	     // setHasTeam(true);
	    }
	  },[location.search]);

	const handleSubmit = async (e) => {
      e.preventDefault();

      setIsLoading(true)

      let opts = {
        team:isTeam,
        user:isUser,
        code:isCode
      };

      const status = await requestRemoval(opts);

     // const status = 'removed'; // testing removed
     // const status = 'nouser';  // testing nouser
     // const status = 'expired';  // testing expired

      if (status === 'error') {
        setHasError(true);
        setSuccess('none');
        return
      }
    
      setSuccess(status);
    }

	return (
		<div>
		  {
            hasError ? 
            <Box p={4} my={3} textAlign="center">
              <h1><span role="img" aria-label="confused"> 😕 </span>  Remove <span role="img" aria-label="confused"> 😕 </span> </h1>
            </Box>
            : 
            <Box p={4} my={3} textAlign="center">
              <h1><span role="img" aria-label="pleading"> 🥺 </span> Remove <span role="img" aria-label="pleading"> 🥺 </span></h1>
            </Box>
          }
          {
          	success !== 'none' ?
          	  <SuccessBox success={success}/>
		    :
            hasError ?
              <ErrorBox/> 
        :
          <Container maxWidth="sm">
           <Box className={classes.removeBox}>
              <p><strong>This action will delete your account!</strong></p> 
              <p>If you are the last team member from your workspace, your team will be unregistered from POLLEN.</p>
        	    <Box m={2}>
           	  	{isLoading ? <LoadSpinner /> : <button className="btn" onClick={handleSubmit}>Remove</button>}
        	    </Box>
        	  </Box>
            </Container>
          }
		</div>
		);
	}

export default Remove;