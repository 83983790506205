import React from "react";

import Box from '@material-ui/core/Box';
import Container from '@material-ui/core/Container';

function Terms() {
	return (
		<div>
		 <Box p={4} my={3} textAlign="center">
       		<h1>Terms of Use <span role="img" aria-label="eyes">👀</span></h1>
     	 </Box>
		 <Box p={2} my={3} textAlign="left">
         <Container maxWidth="sm" >
		    <p>
				Thank you for exploring POLLEN with us. 
			</p><p>
				We are a small team dedicated to making POLLEN a great experience for your Slack Workspace. We hope you enjoy using POLLEN as much as we do.
			</p><p>
				That said, there are a few things we need to go over before you register POLLEN for your team, just to make sure we are clear on what happens when you use POLLEN.
			</p><p>
				Unfortunately, if you don’t agree with these terms, you are not allowed to use POLLEN. <span role="img" aria-label="slightly-frowning-face">🙁</span> So read these terms carefully.
			</p><p>
				First thing’s first, POLLEN is developed and hosted by Pixels and Plans through pollen.pixelsandplans.com and POLLEN’s Slack App, which is accessible through your Slack workspace. Together, these services are what we are calling POLLEN.
			</p>
				
			<h2>Use of Slack Data</h2>
				
			<p>
				The availability of POLLEN is dependent upon your entering into a valid license to use Slack.  The Terms you are reading now are between you and POLLEN, and not with Slack. You must agree to comply with Slack’s terms of use when using POLLEN. 
			</p><p>
				POLLEN’s channel viewer displays your channels on a public facing website. Pixels and Plans encourages you to look over POLLEN's <a href="/privacy">Privacy Policy</a> in addition to these Terms of Use if you plan to install POLLEN on your workspace.
			</p>

			<h2>Use of Personal Data</h2>

			<p>
				To use POLLEN, we require a minimum of one member from your Slack Workspace to register with us using a name and a valid email address. We collect this data in order to communicate with you about updates, and we might let you know of a paid upgrade for Pollen, should we decide to build one. 
			</p><p>
				When you sign up for POLLEN, you agree to have your name and email stored with Stripe (https://stripe.com) on behalf of Pixels and Plans. It is important to understand that Pixels and Plans does not store your name and e-mail on our servers. Your name and e-mail are stored with Stripe. In the event you decide to purchase a product from Pixels and Plans in the future, Stripe will store your payment information in addition to your name and email address.
			</p><p>
				You are encouraged to read Stripe’s own service agreement, and in particular Stripe’s section on Data Usage, Privacy and Security: https://stripe.com/en-ca/ssa#section_d.
			</p><p>
				You can terminate your account at any time. When you terminate your account, your data is immediately removed from Stripe. If you are the last person to terminate your account from your team, your team’s registration is removed from POLLEN.
			</p>
				
			<h2>Service</h2>

			<p>
				WE ARE PROVIDING POLLEN ON AN “AS IS” AND “AS AVAILABLE” BASIS, WITHOUT WARRANTY OF ANY KIND, EXPRESS OR IMPLIED. YOUR SOLE REMEDY FOR DISSATISFACTION WITH POLLEN IS TO STOP USING POLLEN.
			</p><p>
				You must agree to defend and indemnify POLLEN and Pixels and Plans and hold us harmless against any and all legal claims and demands, including reasonable attorney fees, which may arise from or relate to your use or misuse of these services, your breach of these terms and conditions, or your conduct or actions. We will select our own legal counsel and may participate in our own defence, if we wish so. 
			</p><p>
				We can’t imagine it coming to that, but we want to be sure you understand that you are using POLLEN because you want to, and that it will be a benefit to your team’s workspace. If POLLEN fails to meet your expectations in any way, we ask that you uninstall POLLEN from your workspace.
			</p><p>
				Uninstalling POLLEN will automatically remove all team members from stripe, and remove all associated data from our servers.
			</p><p>
				Finally, we are really interested in making POLLEN a great experience, and are happy to accept feedback. Please don’t hesitate to <a href="https://pixelsandplans.com/contact/">contact us</a> about our terms of service, our privacy statement, or POLLEN.
			</p>
		 </Container>
        </Box>

		</div>
	)
}

export default Terms;