import { createMuiTheme } from '@material-ui/core/styles'
import "typeface-montserrat";

 // overrides: {
 //      MuiToolbar: {
 //        gutters: {
 //          [defaultTheme.breakpoints.up('sm')]: {
 //            paddingLeft: '10%',
 //            paddingRight: '10%',
 //          },
 //        },
 //    },
 //  },

export const theme = createMuiTheme({
  typography: {
    fontSize: 18,
    fontFamily: [
      'Montserrat',
      'serif',
    ].join(','),
  },
  "palette": {
    "common": {
      "black": "#000",
      "white": "#fff"
    },
    "background": {
      "paper": "#fa9b0b",
      "default": "#181718"
    },
    "primary": {
      "light": "#4adffa", // ['#fa9b0b','#4adffa'];	
      "main": "#4adffa",
      "dark": "#1c1b1c",
      "contrastText": "#000"
    },
    "text": {
      primary: "#fff",
      secondary: "#ccc",
      disabled: "#aaa",
      hint: '#fa9b0b'
    },
  }
  });

  export default theme;