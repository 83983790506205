import React, { useState, useEffect } from "react";
import { makeStyles } from '@material-ui/core/styles';

import { useLocation} from "react-router-dom";

import {ExpireBox} from './Components/Forms/General';
import LoadSpinner from './Components/LoadSpinner/LoadSpinner';
import {ErrorBox} from "./Components/Forms/General";
import Box from '@material-ui/core/Box';
import Container from '@material-ui/core/Container';

import Stripe from "./Components/Stripe/Stripe";

const useStyles = makeStyles((theme) => ({
      disclaimer: {
        margin:'20px',
        padding:'20px',
        borderRadius:'5px',
        background: 'rgba(0,0,0,0.1)',
        border: '2px solid black'
      },
      plan: {
      	margin:'20px',
        padding:'20px',
        borderRadius:'5px',
        background: 'rgba(0,0,180,0.1)',
        border: '2px solid black',
        cursor:'pointer',
        '&:hover': {
          background: 'rgba(0,0,255,0.1)',
          border: '2px solid white'
        }
      }
  }));

const checkPrices = async (isTeam,isUser,isCode) => {

  try {
    const result = await fetch(`/${process.env.REACT_APP_SLACK}/checkprices`, {
      method: 'POST',
      body: JSON.stringify({team:isTeam,user:isUser,code:isCode}),
      headers: {"Content-type": "application/json; charset=UTF-8"}
    });
    const response = await result.json();

    return response;
  } 
  catch (error) {
    return {customer:false};
  }
}

function Upgrade() {

	const [hasPlan, setHasPlan] = useState(false);
	const [plan, setPlan] = useState(null);

	const [hasError, setHasError] = useState(false);
	const [pricelist, setPrices] = useState(null);
	const [customer, setCustomer] = useState(null);
	const [status, setStatus] = useState(null);

	const [hasTeam, setHasTeam] = useState(false);
    const [isTeam, setIsTeam] = useState(null);
	const [isUser, setIsUser] = useState(null);
	const [isCode, setIsCode] = useState(null);

	const location = useLocation();

	const classes = useStyles();

	const selectPlan = (type) => {
		setHasPlan(true);
		setPlan(type);
	}

	useEffect(() => {

		if (location.search.length !== 0) {

	      let querystr = location.search.split('?');
	      if (querystr.length <= 1) return;

	      let entries = querystr[1].split('.');
	      if (entries.length <= 2) return;
	     
	     console.log(entries);
	       	setIsTeam(entries[0]);
        	setIsUser(entries[1]);
        	setIsCode(entries[2]);

	      	setHasTeam(true);
	    }
	},[location.search]);

	useEffect(() => {
		if (isUser === null) {return;}
		if (isTeam === null) {return;}

	    async function fetchPrice() {
	        const successData = await checkPrices(isTeam,isUser,isCode);

	        console.log('successData');
	        console.log(successData);

	        if (!successData.customer) {
	          setHasError(true);
	          return;
	        }

	        // testing: get prices
	        //const successData = {customer:{id:'444',payment:false},price:[{id:'333',time:'month',unit:5},{id:'334',time:'year',unit:50}]};
	        
	        // testing: already subscribed
	      	//const successData = {customer:{id:'444',payment:true},price:[{id:'333',time:'month',unit:5},{id:'334',time:'year',unit:50}]};


	        // testing: expired
	        //const successData = {status:'expired'}

	        setStatus(successData.status);

	        setCustomer(successData.customer)
	        setPrices(successData.price);
	    }
	    
	    fetchPrice();

	},[isTeam,isUser,isCode]);

	return (
		<div>
			{
			hasError ? 
				<div>
					<Box p={4} my={3} textAlign="center">
						<h1><span role="img" aria-label="confused"> 😕 </span>  Upgrade <span role="img" aria-label="confused"> 😕 </span> </h1>
					</Box>
					<ErrorBox/> 
				</div>
			: 
				<div>
					<Box p={4} my={3} textAlign="center">
						<h1><span role="img" aria-label="smiley">😀</span> Upgrade <span role="img" aria-label="smiley">😀</span></h1>
					</Box>
					{
						status === 'expired' ?
							<ExpireBox/>
						: 
							customer?.payment ?
								<Box my={3} textAlign="center">
									<p>Your team has already subscribed</p>
								</Box>
							:
								hasPlan ?
									<Stripe plan={plan} hasTeam={hasTeam} isTeam={isTeam} isUser={isUser} isCode={isCode} customer={customer}/>
								:
									<div>
										<Box my={3} textAlign="center">
											<h2>Choose Your Plan</h2>
										</Box>
										{
											pricelist ?
												pricelist.map((price, index) => {
													return (
														<Container maxWidth="sm">
															<Box className={classes.plan} onClick={() => selectPlan(price)}> 
																<p>{`Customize Pollen for a ${price.time}`}</p>
																<p>{`$${price.unit}/${price.time}`}</p>
															</Box>
														</Container>
													)
							       				})
							       			:
							       			<Box p={4} textAlign="center">
							       				<LoadSpinner/>
							       			</Box>
						      			}
						      			<Container maxWidth="sm">
											<Box p={4} my={3} className={classes.disclaimer}>
												<p>All subscriptions are billed automatically.</p>
												<p>Cancel any time. Just select <em>Cancel</em> in the Slack Home Tab once you have subscribed.</p>
											</Box>
										</Container>
									</div>
						}
				</div>
			}
		</div>
		);
	}

export default Upgrade;