import React from "react";
import Box from '@material-ui/core/Box';
import Container from '@material-ui/core/Container';

function Support() {
  return (
    <div>
    	<Box p={4} my={3} textAlign="center">
  	   <h1><span role="img" aria-label="thinking_face">🤔</span> Support <span role="img" aria-label="thinking_face">🤔</span></h1>
  	  </Box>
    	<Box p={2} my={3} textAlign="left">
        <Container maxWidth="sm" >
          <p>If you are having difficulties with installation, please refer to the <a href="/guide">Guide</a>.</p>
        	<p>For all other inquiries, please <a href="https://pixelsandplans.com/contact/">contact us</a>!</p>
          <p>POLLEN is developed by <a href="https://pixelsandplans.com">Pixels and Plans</a>.</p>
	 		  </Container>
      </Box>
 	</div>
  );
}
 
export default Support;