import React from 'react';
import {Elements} from '@stripe/react-stripe-js';
import {loadStripe} from '@stripe/stripe-js';

import CheckoutForm from '../CheckoutForm/CheckoutForm';

// Make sure to call `loadStripe` outside of a component’s render to avoid
// recreating the `Stripe` object on every render.
const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PK);

function Stripe({plan,hasTeam,isTeam,isUser,isCode,customer}) {
  return (
	<Elements stripe={stripePromise}>
	    <CheckoutForm plan={plan} hasTeam={hasTeam} isTeam={isTeam} isUser={isUser} isCode={isCode} customer={customer} />
	</Elements>
  );
};

export default Stripe;