import React from "react";

import Box from '@material-ui/core/Box';
import Container from '@material-ui/core/Container';

//     <Box display="flex" flexDirection="column" alignItems="center">
 //       <Box className={classes.section} p={1}>
  //      </Box>
   //  </Box>


function Privacy() {
  return (
    <div>
      <Box p={4} my={3} textAlign="center">
       <h1>Privacy Policy <span role="img" aria-label="eyes">👀</span></h1>
      </Box>
        <Box p={2} my={3} textAlign="left">
         <Container maxWidth="sm" >
          <h2>Out of the Box <span role="img" aria-label="package">📦</span></h2>

          <p>When POLLEN’s Channel Viewer is launched from your Slack workspace, the url is available for anyone to see. You can disable the url by uninstalling POLLEN, or by disabling the Channel Viewer from POLLEN’s home tab within your Slack workspace.</p>

          <h2>Registration <span role="img" aria-label="paper">📄</span></h2>

          <p>
            In order to use POLLEN, at least one member from your team must register with <a href="https://pixelsandplans.com/">Pixels and Plans</a>. We ask for registration in order to communicate with you about updates.
          </p> 

          <h2>Scope <span role="img" aria-label="telescope">🔭</span></h2>

          <p>
            POLLEN uses <strong>scopes</strong> from the <a href="https://api.slack.com/">Slack API</a> to access the following information from your team’s workspace.
          </p>

          <p>
            1. Display your team's logo and domain name.
            <ul>
              <li>Scope: <a href="https://api.slack.com/scopes/team:read">team:read</a></li>
              <li>This is used to identify your team and display your team's logo.</li>
            </ul>
          </p>
          <p>
            2. List channel names and ids.
            <ul>
              <li>Scope: <a href="https://api.slack.com/scopes/channels:read">channels:read</a></li>
              <li>Public facing channels, descriptions, and number of members are displayed.</li>
            </ul>
          </p>

          <p>
            In the future, we plan to release POLLEN features with emoji visualizations, therefore we are also requesting permission for the following events:
          </p>
          
          <p>
            3. Events from participating channels, if enabled (disabled by default). 
            <ul>
              <li>Scope: <a href="https://api.slack.com/scopes/reactions:read">reactions:read</a></li>
              <li>This is used to determine when an emoji reaction is added or removed in enabled channels.</li>
            </ul>
          </p>
           <p>
            4. Display custom emojis, if enabled (disabled by default).
            <ul>
              <li>Scope: <a href="https://api.slack.com/scopes/emoji:read">emoji:read</a></li>
              <li>This is used to display custom emojis in addition to unicode emojis in enabled channels.</li>
            </ul>
          </p>

          <Box my={6} >
            <em>POLLEN does not, or will ever, have access to your workspace information outside of the scopes listed above.</em>
           </Box>

          <h2>Storage <span role="img" aria-label="floppy_disk">💾</span></h2>
            
           <p>
             1. When you register, your name and email address is stored and secured with <a href="https://stripe.com/">Stripe</a>. You can remove your information at any time as follows:
           </p>
           <ul>
           <li>Select <em>Remove Me</em> in POLLEN’s <a href="https://slack.com/app_redirect?app=A01560LC91D">Home Tab</a></li>
           </ul>
           <p>
             If you are the last team member to remove themselves, your team will be automatically unregistered from POLLEN.
           </p>

           <p>
           2. For the purpose of accessing your workspace, POLLEN stores your team id along with a token that allows access within the scopes described above. You can remove this token at any time as follows:
           </p>
             <ul>
           <li>Select <em>Remove App</em> at the bottom of POLLEN's <a href="https://pixelsandplans.slack.com/apps/A01560LC91D-pollen">Slack App Directory page</a>. This removes the tokens associated with the scopes described above from your workspace.</li>
           </ul>

           

          </Container>
        </Box>

      </div>
  );
}
 
export default Privacy;