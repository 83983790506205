import React from "react";
import { makeStyles } from '@material-ui/core/styles';

import Box from '@material-ui/core/Box';
import Container from '@material-ui/core/Container';

const useStyles = makeStyles((theme) => ({
  diagram: {
    width: '100%'
  }
}));

function Guide() {

  const classes = useStyles();

  return (
  <div>
  	 <Box p={4} my={3} textAlign="center"> 
      <h1><span role="img" aria-label="smiley">😀</span> User Guide <span role="img" aria-label="smiley">😀</span></h1>
     </Box>
     <Box p={2} my={3}  textAlign="left">
        <Container maxWidth="sm">
          <h2>This is the user guide (in progress).</h2>
          <p>
          POLLEN's channel viewer organizes your channels based on Slack’s <a href="https://slack.com/intl/en-ca/help/articles/217626408-Create-guidelines-for-channel-names">recommended naming guidelines</a>. 
          </p>
         
          <Box p={4} my={3} border={1} borderRadius={5}>
            <p>
            We use hyphens to split channels into categories following this pattern:  <code>"category" - "topic"</code>
            </p>
            <p>
            Take the following channel names, for example
            </p>
            <p>
            <div><code>Channel 1: main-home</code></div>
            <div><code>Channel 2: main-discuss</code></div>
            <div><code>Channel 3: main-suggestions</code></div>
            </p>
            <p> These channels will be organized into a category called <code>main</code> </p>
            <div><code>Category: main</code></div> 
            <p>with the following topics:</p>
            <div><code>Topic 1: home</code></div>
            <div><code>Topic 2: discuss</code></div>
            <div><code>Topic 3: suggestions</code></div>
            
             <Box p={2} my={3} >
              <img src="images/slack_example_clear.png" className={classes.diagram} alt="diagram of nodes"/>
             </Box>
          </Box>
          <Box p={4} my={3} textAlign="center">
          <em>The Channel Viewer splits your channels into categories with associated topics.</em>
          </Box>

          <Box p={4} my={3} border={1} borderRadius={5}>
          <p> Channels without a hyphen will automatically be assigned to your main team</p>
          <p>For example:</p>
          <p>
          <div><code>Channel 1: home</code></div>
          <div><code>Channel 2: discuss</code></div>
          <div><code>Channel 3: suggestions</code></div>
          </p>
          <p> These channels will be organized into your team cateogry </p>
          <div><code>Category: Your team name</code></div> 
          <p>with the following topics:</p>
          <div><code>Topic 1: home</code></div>
             <div><code>Topic 2: discuss</code></div>
             <div><code>Topic 3: suggestions</code></div>
            </Box>

            <Box p={4} my={3} textAlign="center">
          	<em>If you don't use hyphens, all topics will be attached to your team name!</em>
           </Box>

           <h2>Other notes</h2>
           <Box p={4} my={3} border={1} borderRadius={5}>
           <p> Channels with more than one hyphen will assume the first word is the cateogry, and keep any hyphens afterward. This means categories can't have hyphens, but topics can.</p>
           <p>For example:</p>
            <p>
             <div><code>Channel 1: main-san-fransisco</code></div>
             <div><code>Channel 2: main-we-should-discuss-this</code></div>
             <div><code>Channel 3: main-suggestions</code></div>
           </p>
            <p> These channels will be organized into a category called  </p>
            <div><code>Category: main</code></div> 
            <p>with the following topics:</p>
             <div><code>Topic 1: san-fransisco</code></div>
             <div><code>Topic 2: we-should-discuss-this</code></div>
             <div><code>Topic 3: suggestions</code></div>
             </Box>
              <Box p={4} my={3} border={1} borderRadius={5}>
             <p> This also means a city cannot use a hyphen if it is more than one word. We suggest using underscores if it is necessary to have more than one word in a category.</p>
             <p>For example:</p>
              <Box p={4} my={3} >
             <p>
             <div><code>Channel: san-fransisco-general</code></div>
             </p>
              <p>Becomes ...</p>
              <p>
             <div><code>Category: san</code></div>
             <div><code>Topic: fransisco-general</code></div>
              </p>
             <p><span role="img" aria-label="confused"> 😕 </span> <code>san? ...</code></p>
            </Box>

          	
          	 <p>Whereas</p>
          	  <Box p={4} my={3} >
          	 <p>
             <div><code>channel: san_fransisco-general</code></div>
             </p>
             <p>Becomes ...</p>
             <p>
             	<div><code>Category: san_fransisco</code></div>
             	<div><code>Topic: general</code></div>
          	 </p>
          	 <p><span role="img" aria-label="smiley"> 😀 </span> </p>
          	 </Box>
          	 </Box>
          </Container>
      </Box>
  </div>
  );
}

export default Guide;