import React, { useState, useEffect }  from "react";
import { makeStyles } from '@material-ui/core/styles';

import Box from '@material-ui/core/Box';
import Container from '@material-ui/core/Container';

const useStyles = makeStyles((theme) => ({
  video: {
    width: '100%',
    border: '1px solid white'
  },
  header_img: {
    width: '100%'
  },
  errorTxt: {
    color:'#FF0000'
  }
}));

function Home() {

  const classes = useStyles();

  const [success, setSuccess] = useState(true);
  const [slackButton, setSlackButton] = useState({__html:null});

  useEffect(() => {

    (function fetchData() {
      fetch(`/${process.env.REACT_APP_SLACK}/install/`)
        .then(res => res.text() )
        .then(res => {
          setSuccess(true);
          setSlackButton( {__html: res} )
         })
        .catch(err => setSuccess(false));
      })();
  },[]);

  return (
    <div>
      <Box p={4} my={3} textAlign="center">
        <h1>Visualize and discover your Slack channels.</h1>
        <Box my={5}>
          {
            success ? <Box dangerouslySetInnerHTML={slackButton}></Box>
            : <Box className={classes.errorTxt}><h2>Pollen is currently not available.</h2><p>Please try back later!</p></Box>
          }
        </Box>
      </Box>
      <Container maxWidth="lg" >
        <Box my={10}>
          <img src="pollen.png" className={classes.header_img} alt="Screenshot of the Pixels and Plans workspace, as seen in POLLEN"/>
        </Box>
      </Container>
      <Container maxWidth="sm" >
        <Box p={2} my={3} textAlign="left">
             <h2>What is POLLEN?</h2>
             <p>
                POLLEN is a Slack app that helps you and your team <strong>visualize</strong>, <strong>discover</strong> and <strong>visit</strong> your workspace channels all in one place in POLLEN’s <strong>Channel Viewer</strong>.
             </p><p>
                POLLEN also helps you <strong>name</strong> and <strong>organize</strong> the channels by grouping them into categories based on Slack’s <a href="https://slack.com/intl/en-ca/help/articles/217626408-Create-guidelines-for-channel-names">recommended naming guidelines</a>. 
             </p><p>
                In addition, POLLEN’s <strong>Channel Viewer</strong> updates when you add or remove workspace channels. 
             </p>
        </Box>
        <Box p={2} my={3} textAlign="left">  
          <h2>How POLLEN works:</h2>
          <p>
          	Visualize your Slack channels in 3 easy steps:
          </p>
          <ol>
            <li>Install the POLLEN App onto your Slack workplace</li>
            <li>Register your team*</li>
            <li>Share a unique url to POLLEN’s Channel Viewer with your team, or link/embed it on your website</li>
          </ol>
          <p>
            * One registration / email address per team is required. POLLEN uses the Slack API to access information about your workspace in order to visualize your channels. For more information, please visit our <a href="/privacy">Privacy Policy</a>.
          </p>
        </Box>
        <Box p={2} mt={3} textAlign="left">
          <h2>Install POLLEN!</h2>
          <p>
            You can install POLLEN by following the instructions from this button, or visit POLLEN in the Slack App Directory.
          </p>
        </Box>
        <Box p={2} my={2} textAlign="center">
          {
            success ? <Box dangerouslySetInnerHTML={slackButton}></Box>
            : <Box className={classes.errorTxt}><h2>Pollen is currently not available.</h2><p>Please try back later!</p></Box>
          }
        </Box>
      </Container>
   </div>
  );
}
 
export default Home;