import React from "react";

import Box from '@material-ui/core/Box';
import Container from '@material-ui/core/Container';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  img: {
    width: '100%',
    border: '1px solid white'
  },
  caption: {
    fontSize:"75%",
    textAlign:"center"
  }
}));

function About() {

  const classes = useStyles();

  return (
    <div>
      <Box p={4} my={3} textAlign="center">
        <h1>About POLLEN.</h1>
      </Box>
      <Box p={2} my={3} textAlign="left">
        <Container maxWidth="sm" >
          POLLEN started as an idea to visualize Slack workspace channels for the <a href="https://www.datavisualizationsociety.com/">Data Visualization Society</a>. The society has over 70 channels and it was difficult for members to get a sense of the channel landscape and the amazing discussions.
        </Container>
      </Box>
      <Box p={2} my={3}>
        <Container maxWidth="md" >
          <figure>
            <img src="dvs.png" className={classes.img} alt="A view of the Data Visualization Society Slack channels, as seen in POLLEN"/>
            <figcaption className={classes.caption}>A view of the Data Visualization Society Slack channels</figcaption>
          </figure>
        </Container>
      </Box>
      <Box p={2} my={3} textAlign="left">
        <Container maxWidth="sm" >
          Working to solve this problem quickly evolved into creating a new Slack App - POLLEN. You can read about the inspiration and the process of creating POLLEN in this <a href="https://juliakrolik.medium.com/c36277cdfc1f?source=friends_link&sk=2754da3133db3af8c059ee3beadfd439">Nightingale article</a>. 
        </Container>
      </Box>
      <Box p={2} my={3}>
        <Container maxWidth="md" >
          <figure>
            <img src="scicomm.png" className={classes.img} alt="A view of the Science Communication Network Slack channels"/>
            <figcaption className={classes.caption}>A view of the Science Communication Network Slack channels</figcaption>
          </figure>
        </Container>
      </Box>
       <Box p={2} my={3}>
        <Container maxWidth="sm" >
          We hope you enjoy it!
        </Container>
      </Box>
   </div>
  );
}
 
export default About;