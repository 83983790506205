import React, { useState } from "react";
import {useStripe, useElements, CardElement} from '@stripe/react-stripe-js';
import { makeStyles } from '@material-ui/core/styles';

import CardSection from '../CardSection/CardSection';

import LoadSpinner from '../LoadSpinner/LoadSpinner';
import Box from '@material-ui/core/Box';
import Container from '@material-ui/core/Container';
import Typography from '@material-ui/core/Typography';

const useStyles = makeStyles((theme) => ({
    formBox: {
        margin:'40px 0',
        padding:'5px',
        borderRadius:'5px',
        background: 'rgba(100,100,100,0.1)',
        border: '2px solid black'
     },
     chargeError: {
        border: '2px solid red',
        padding: '20px',
        borderRadius:'5px',
        background: 'rgba(100,0,0,0.1)',
        textAlign: 'center',
      },
      errorTxt: {
         color:'#FF0000',
         fontSize:'15px'
       },
      icon: {
        fontSize:'70px'
      },
      successBox: {
        margin:'40px 0',
        padding:'20px',
        borderRadius:'5px',
        background: 'rgba(0,255,0,0.1)',
        border: '2px solid green'
      }
}));

export default function CheckoutForm({plan,hasTeam,isTeam,isUser,isCode,customer}) {

  const stripe = useStripe();
  const elements = useElements();
  const [hasError, setHasError] = useState(false);
  const [errorStr, setErrorStr] = useState('No error output found.');
 
  //const [secret, setSecret] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [success, setSuccess] = useState(false);

  const classes = useStyles();

  const handleSubmit = async (event) => {

    event.preventDefault();

     if (!stripe || !elements) {
      // Stripe.js has not yet loaded.
      // Make sure to disable form submission until Stripe.js has loaded.
      return;
    }

    /*
    function retryInvoiceWithNewPaymentMethod({
      customerId,
      paymentMethodId,
      invoiceId,
      priceId
      }) {
        return (
          fetch('/retry-invoice', {
            method: 'post',
            headers: {
              'Content-type': 'application/json',
            },
            body: JSON.stringify({
              customerId: customerId,
              paymentMethodId: paymentMethodId,
              invoiceId: invoiceId,
            }),
          })
            .then((response) => {
              return response.json();
            })
            // If the card is declined, display an error to the user.
            .then((result) => {
              if (result.error) {
                // The card had an error when trying to attach it to a customer.
                throw result;
              }
              return result;
            })
            // Normalize the result to contain the object returned by Stripe.
            // Add the additional details we need.
            .then((result) => {
              return {
                // Use the Stripe 'object' property on the
                // returned result to understand what object is returned.
                invoice: result,
                paymentMethodId: paymentMethodId,
                priceId: priceId,
                isRetry: true,
              };
            })
            // Some payment methods require a customer to be on session
            // to complete the payment process. Check the status of the
            // payment intent to handle these actions.
            //.then(handlePaymentThatRequiresCustomerAction)
            // No more actions required. Provision your service for the user.
            .then(() => {
                setIsLoading(false);
                setSuccess(true);
              }
            )
            .catch((error) => {
              // An error has happened. Display the failure to the user here.
              // We utilize the HTML element we created.
            
              setIsLoading(false);

              setHasError(true);
              setErrorStr(error);

            })
        );
      }
  */  
    function handleRequiresPaymentMethod({
      subscription,
      paymentMethodId,
      priceId,
    }) {
      if (subscription.status === 'active') {
        // subscription is active, no customer actions required.
        return { subscription, priceId, paymentMethodId };
      } else if (
        subscription.latest_invoice.payment_intent.status ===
        'requires_payment_method'
      ) {
        // Using localStorage to manage the state of the retry here,
        // feel free to replace with what you prefer.
        // Store the latest invoice ID and status.
        localStorage.setItem('latestInvoiceId', subscription.latest_invoice.id);
        localStorage.setItem(
          'latestInvoicePaymentIntentStatus',
          subscription.latest_invoice.payment_intent.status
        );
        // throw { error: { message: 'Your card was declined.' } };
      } else {
        return { subscription, priceId, paymentMethodId };
      }
    }

    function createSubscription({ customerId, paymentMethodId, priceId }) {
      return (
        fetch(`/${process.env.REACT_APP_SLACK}/create-subscription`, {
          method: 'post',
          headers: {
            'Content-type': 'application/json',
          },
          body: JSON.stringify({
            customerId: customerId,
            paymentMethodId: paymentMethodId,
            priceId: priceId,
            teamId:isTeam,
            userId:isUser,
            code:isCode
          }),
        })
          .then((response) => {
            const json = response.json();
            console.log(json)
            return json;
          })
          // If the card is declined, display an error to the user.
          .then((result) => {
            if (result.error) {
              // The card had an error when trying to attach it to a customer.

              setIsLoading(false);

              setHasError(true);
              setErrorStr(result.error.message);

              throw result;
            }
            return result;
          })
          // Normalize the result to contain the object returned by Stripe.
          // Add the additional details we need.
          .then((result) => {
            return {
              paymentMethodId: paymentMethodId,
              priceId: priceId,
              subscription: result,
            };
          })
          // Some payment methods require a customer to be on session
          // to complete the payment process. Check the status of the
          // payment intent to handle these actions.
          // .then(handlePaymentThatRequiresCustomerAction)
          // If attaching this card to a Customer object succeeds,
          // but attempts to charge the customer fail, you
          // get a requires_payment_method error.
          .then(handleRequiresPaymentMethod)
          // No more actions required. Provision your service for the user.
          .then( () => {
            setIsLoading(false);
            setSuccess(true);
          })
          .catch((error) => {
            // An error has happened. Display the failure to the user here.
            // We utilize the HTML element we created.
            //showCardError(error);

            setIsLoading(false);

             setHasError(true);
             setErrorStr(error);
          })
      );
    }

    function createPaymentMethod(card) {
     
      // Set up payment method for recurring usage
      stripe
        .createPaymentMethod({
          type: 'card',
          card: card,
          billing_details: {
            name: customer.name,
          },
        })
        .then((result) => {
          if (result.error) {
             setIsLoading(false);

             setHasError(true);
             setErrorStr(result.error.message);
          } else {
            
              createSubscription({
                customerId: customer.id,
                paymentMethodId: result.paymentMethod.id,
                priceId: plan.id,
              });
           
          }
        });
      }

      setIsLoading(true);

      createPaymentMethod(elements.getElement(CardElement));
  }
  
  return (
    <div>
      {
        success ? 
          <Container maxWidth="sm">
            <Box className={classes.successBox}>
              <h2>Payment Received!</h2>
              <p>Visit <a href={`https://slack.com/app_redirect?app=${process.env.REACT_APP_SLACK_APP_ID}`}>Pollen in Slack</a> and start customizing!</p>
            </Box>
          </Container>
          : 
          hasError ? 
            <Container maxWidth="sm">
              <Box p={4} my={3} className={classes.chargeError}>
                <h2> There was an error.</h2>
                <Typography className={classes.icon}>
                  <span role="img" aria-label="thinking face">🤔</span>
                </Typography>
                <p>You have not been charged. Please review the error below and try submitting again.</p>
                <Container>
                  <p className={classes.errorTxt}><code>{errorStr}</code></p>
                </Container>
                <p> If the problem persists, please <a href="/support">contact us</a>.</p>
              </Box>
            </Container>
          :
            <Container maxWidth="sm">
              <Box p={4} my={3}>
                    <p>Plan selected: {`${plan.time}ly`} <span role="img" aria-label="checkmark">✓</span> <a href="javascript:window.location.reload(true)">change</a></p>  
                    <p>Total due now: {`$${plan.unit}`}</p> 
              </Box>
              <Box className={classes.formBox}> 
                  <form onSubmit={handleSubmit}>
                    <CardSection isLoading={isLoading}/>
                    {
                      isLoading ? 
                        <Box p={4} textAlign="center">
                          <LoadSpinner />
                        </Box>
                        :
                        <Box m={3} textAlign="center">
                            <button className="btn" disabled={!stripe}>Subscribe</button>
                        </Box>
                    }
                  </form>
              </Box>
            </Container>
      }
    </div>
  );
}