import React from "react";
import {BrowserRouter as Router} from "react-router-dom";

import { ThemeProvider } from '@material-ui/core/styles'
import { theme } from './Theme'

import PersistentDrawer from "./Components/Drawer/Drawer";
import './App.css';

function App() {
  return (
    <Router>
     <ThemeProvider theme={theme}>
      <PersistentDrawer />
      </ThemeProvider>
    </Router>
  );
}
 
export default App;