import React from "react";
import Box from '@material-ui/core/Box';
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import Typography from '@material-ui/core/Typography';

const useStyles = makeStyles((theme) => ({
  warnBox: {
    border: '2px solid #FFCC00',
    padding:'20px',
    borderRadius:'5px',
    background: 'rgba(0,0,0,0.1)',
    textAlign:'center'
  },
  errorBox: {
    border: '2px solid red',
    padding:'20px',
    borderRadius:'5px',
    background: 'rgba(100,0,0,0.1)',
    textAlign: 'center',
  },
  icon: {
    fontSize:'70px'
  }
}));

export const getUserStatus = async (isTeam,isUser) => {

   // user:false --> no user, Register form
   // user:pending --> user registered but has not validated email, update form
   // user:verified --> user registered and has verified, update form

  try {
    const result = await fetch(`/${process.env.REACT_APP_SLACK}/checkuser`, {
      method: 'POST',
      body: JSON.stringify({team:isTeam,user:isUser}),
      headers: {"Content-type": "application/json; charset=UTF-8"}
    });
    const response = await result.json();

    return response.user;
  } 
  catch (error) {
    return "error";
  }
}

export const requestVerification = async (opts) => {

  try {
    const result = await fetch(`/${process.env.REACT_APP_SLACK}/register`, {
      method: 'POST',
      body: JSON.stringify(opts),
      headers: {"Content-type": "application/json; charset=UTF-8"}
    });

    const response = await result.json();
    return response.status;
  } 
  catch (error) {
    return "error";
  }
}

export const NoTeamBox = () => {

  const classes = useStyles();

  return (
    <Container maxWidth="sm">
      <Box p={2} my={5} className={classes.warnBox}>
        <p>Make sure to <a href={`/`}>install</a> POLLEN</p>
        <p>Then, you can register from POLLEN's <a href={`https://slack.com/app_redirect?app=${process.env.REACT_APP_SLACK_APP_ID}`}>Home Tab</a>.</p>
      </Box>
    </Container>
  )
}

export const ExpireBox = () => {

    const classes = useStyles();

   return (
    <Container maxWidth="sm">
      <Box p={2} my={2} className={classes.warnBox}>
          <h2><span role="img" aria-label="warning">⚠️</span> Your token has expired <span role="img" aria-label="warning">⚠️</span></h2>
          <Typography className={classes.icon}>
            <span role="img" aria-label="alarm clock">⏰</span>
          </Typography>
          <p>Links you generate from Slack are time limited to prevent others from using them maliciously.</p>
          <p>Please load this again from POLLEN's <a href={`https://slack.com/app_redirect?app=${process.env.REACT_APP_SLACK_APP_ID}`}>Home Tab</a>.</p>
       </Box>
    </Container>
 )
}

export const ErrorBox = () => {

   const classes = useStyles();

   return (
    <Container maxWidth="sm">
      <Box p={2} my={2} className={classes.errorBox}>
          <h2>There was an error.</h2>
          <Typography className={classes.icon}>
            <span role="img" aria-label="thinking face">🤔</span>
          </Typography>
          <p>The server is probably down.</p>
          <p>Please try back later.</p>
      </Box>
    </Container>
 )
}