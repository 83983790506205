import React, { useState, useEffect } from "react";
import { useLocation} from "react-router-dom";

import Form from "./Components/Forms/Form";
import {getUserStatus, NoTeamBox, ErrorBox} from "./Components/Forms/General";

import Box from '@material-ui/core/Box';

function Update() {

	const [hasTeam, setHasTeam] = useState(false);
 	const [isTeam, setIsTeam] = useState(null);
 	const [isUser, setIsUser] = useState(null);
 	const [isCode, setIsCode] = useState(null);

 	const [hasError, setHasError] = useState(false);

 	const [status, setStatus] = useState(false);

	const location = useLocation();

  	useEffect(() => {

		if (location.search.length !== 0) {

		  let querystr = location.search.split('?');
		  if (querystr.length <= 1) return;

		  let entries = querystr[1].split('.');
		  if (entries.length <= 2) return;
		  
		  setIsTeam(entries[0]);
		  setIsUser(entries[1]);
		  setIsCode(entries[2]);

		  setHasTeam(true); // by default true if a user was found
		}
 	},[location.search]);

  	useEffect(() => {
	    async function fetchData() {
	        const response = await getUserStatus(isTeam,isUser);
	        console.log(`isTeam: ${isTeam}`)
	        console.log(`isUser: ${isUser}`)
	        console.log(response)

	        if (response=== 'error') {
	          setHasError(true)
	        }

	        // const response = false;  // testing 'no user'
	      // const response = 'pending'; // test 'pending'
	        // const response = 'verified'; // test 'verified'

	        setStatus(response);
	      }
	      fetchData();
	},[isTeam,isUser]);

	return (
		<div>
		{
			hasError ?
				<div>
					<Box p={4} my={3} textAlign="center">
						<h1><span role="img" aria-label="confused"> 😕 </span> Update <span role="img" aria-label="confused"> 😕 </span> </h1>
					</Box>
					<ErrorBox/> 
				</div>
			: 
				<div>
					<Box p={4} my={3} textAlign="center">
						<h1><span role="img" aria-label="smiley"> 😀 </span> Update <span role="img" aria-label="smiley"> 😀 </span></h1>
					</Box>
					{
						hasTeam ?
							!status ?
								 <Box>
									<p>No user found.</p>
									<NoTeamBox/>
								 </Box>
							:
								<Form isTeam={isTeam} isUser={isUser} isCode={isCode} status={status}/>
						:
							<Box>
								<p>No team found.</p>
								<NoTeamBox/>
							</Box>
					}
				</div>
		}
		</div>
		);
	}

export default Update;